import { useState } from "react";
import styles from "./questions-list.module.css";
import rightPaginationArrow from "../../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../../assets/images/leftPaginationArrow.svg";
import { useNavigate } from "react-router-dom";
import CategoryType from "./CategoryType";

export default function QuestionsList() {
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });
  const [currentPage, setCurrentPage] = useState(1);
  const [questions] = useState(
    Array(12)
      .fill(null)
      .map((_, i) => ({
        id: i + 1,
        title: "Design System - UX Master",
        type: "Coding",
      }))
  );
  const navigate = useNavigate();

  const totalPages = 10;

  const handleNextRange = () => {
    if (totalPages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
    if (buttonRange.end <= currentPage) {
      const nextStart = Math.min(buttonRange.start + 1, totalPages - 10 + 1);
      const nextEnd = Math.min(buttonRange.end + 1, totalPages);
      if (currentPage < totalPages) {
        setButtonRange({ start: nextStart, end: nextEnd });
      }
    }
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (buttonRange.start >= currentPage) {
      const prevStart = Math.min(buttonRange.start - 1, totalPages - 10);
      const prevEnd = Math.min(buttonRange.end - 1, totalPages);
      if (currentPage <= buttonRange.start) {
        setButtonRange({ start: prevStart, end: prevEnd });
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = buttonRange.start; i <= buttonRange.end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? styles.active : ""}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>All questions</h1>
        <button
          onClick={() => navigate("/recruiters-view?tab=create-question")}
          className={styles.createButton}
        >
          +Create Question
        </button>
      </header>

      <div className={styles.listHeader}>
        <div className={styles.titleHeader}>TITLE</div>
        <div className={styles.typeHeader}>
          <CategoryType />
        </div>
        <div style={{ width: 55 }}></div>
      </div>
      <div className={styles.listContainer}>
        {questions.map((question, index) => (
          <div key={question.id} className={styles.listItem}>
            <div className={styles.questionInfo}>
              <span className={styles.number}>{question.id}.</span>
              <span className={styles.title}>{question.title}</span>
            </div>
            <div className={styles.type}>{question.type}</div>
            <button className={styles.viewButton}>View</button>
          </div>
        ))}
      </div>

      <div className={styles.questionBankPagination}>
        <div className={styles.paginationNoBtns}>
          {renderPaginationButtons()}
        </div>
        <div className={styles.paginationNextPrevBtn}>
          <button onClick={handlePrevRange} disabled={currentPage === 1}>
            <img src={leftPaginationArrow} alt="Previous" />
          </button>
          <button
            onClick={handleNextRange}
            disabled={currentPage === totalPages}
          >
            <img src={rightPaginationArrow} alt="Next" />
          </button>
        </div>
      </div>
    </div>
  );
}
