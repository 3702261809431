import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import "./Otp.css";
import { useLocation } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import { useAppDispatch } from "../redux/hooks";
import { setTheme, setUser } from "../redux/slices/auth/authSlice";
import image from "../../src/assets/images/assessmentPadLogo.svg";
import BackIcon from "./back.svg";
import Loader from "./common/Loader";
import AssessmentPadLogo from "./AssessmentPadLogo";

export default function Otp() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const role = location.state.role;
  const email = location.state.email;
  const [isLoading, setIsLoading] = useState(false);
  const jobId = localStorage.getItem("jobId");
  console.log("role", location.state.role);

  const [otp, setOtp] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });

  const verifyotp = async () => {
    const enteredOtp = `${otp.first}${otp.second}${otp.third}${otp.fourth}${otp.fifth}${otp.sixth}`;

    try {
      // Send a POST request with the email, role, and entered OTP
      const response = await axiosInstance.post(
        `v1/apis/login/otp/verify?email=${email}&otp=${enteredOtp}`,
        {
          // email: email,
          // otp: enteredOtp,
        }
      );
      setIsLoading(false);

      // Check the response from the server
      if (response.data) {
        dispatch(setUser(response.data));
        dispatch(setTheme("dark"));

        localStorage.setItem("role", role);
        console.log(response.data);

        // Adjust this based on your server's response structure
        if (role === "recruiter") {
          navigate("/recruiters-view");
        } else if (role === "candidate" && jobId) {
          navigate(`/pre-test-arena?jobId=${jobId}`);
          toast.success("Successfully Logged In!");
        } else if (role === "candidate" && !jobId) {
          navigate("/candidates-view");
          toast.success("Successfully Logged In!");
        } else {
          // alert("Invalid OTP. Please try again.");
          toast.error("Invalid OTP. Please try again.");
        }
      }
    } catch (error) {
      console.error("Failed to verify OTP", error);
      // alert("An error occurred. Please try again.");
      toast.error("Something went wrong. Please try again");
    }
  };

  // const verifyotp = async () => {
  //   const enteredOtp = `${otp.first}${otp.second}${otp.third}${otp.fourth}${otp.fifth}${otp.sixth}`;
  //   const token = localStorage.getItem("authToken"); // Retrieve the token from local storage

  //   try {
  //     // Send a GET request with the email, role, entered OTP, and token as query parameters
  //     const response = await axiosInstance.get("jobify/apis/otp/verify", {
  //       params: {
  //         otp: enteredOtp,
  //         token: token,
  //         role: role, // Include the "role" as a query parameter
  //       },
  //     });

  //     // Check the response from the server
  //     if (response.data) {
  //       // Adjust this based on your server's response structure
  //       if (role === "RECRUITER") {
  //         navigate("/rhome");
  //       } else if (role === "CANDIDATE") {
  //         navigate("/chome");
  //         toast.success("Successfully Logged In!");
  //       }
  //     } else {
  //       alert("Invalid OTP. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Failed to verify OTP", error);
  //     alert("An error occurred. Please try again.");
  //   }
  // };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && e.target.value === "") {
      const prevSibling = e.target.previousSibling;
      if (prevSibling && prevSibling.tagName === "INPUT") {
        prevSibling.focus();
      }
    }
  };
  const handleChange = (e) => {
    const { id, value, maxLength } = e.target;
    setOtp((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    if (value.length === maxLength) {
      const nextSibling = e.target.nextSibling;
      if (nextSibling && nextSibling.tagName === "INPUT") {
        nextSibling.focus();
      }
    }
  };

  const getCamelCaseText = (text) => {
    if (typeof text === "string" && text.length >= 1)
      return text[0].toUpperCase().concat(text.substring(1, text.length));

    return text;
  };

  const sendOtpRequest = () => {
    setIsLoading(true);
    verifyotp();
  };

  const pasteOtp = (otp) => {
    setOtp({
      first: otp[0],
      second: otp[1],
      third: otp[2],
      fourth: otp[3],
      fifth: otp[4],
      sixth: otp[5],
    });
  };

  const focusLastOtpCell = () => {
    document.getElementById("sixth").focus();
  };

  const pasteAndFocusOnLastCell = (otpText) => {
    pasteOtp(otpText);
    focusLastOtpCell();
  };

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  const ifPastedTextIsOtp = (text) => {
    return isNumeric(text) && text.length === 6;
  };

  const handlePaste = (event) => {
    const otpText = event.clipboardData.getData("text");
    ifPastedTextIsOtp(otpText) && pasteAndFocusOnLastCell(otpText);
  };

  useEffect(() => {
    const handlePasteEvent = (event) => {
      handlePaste(event);
    };
    window.addEventListener("paste", handlePasteEvent);
  }, []);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        sendOtpRequest();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [otp]);

  return (
    <div className="otp-container" data-colors-mode="dark">
      <div className="login-box">
        <a href="/" className="otp-jobify-image">
          <AssessmentPadLogo />
        </a>
        <div className="otp-frame">
          <div className="p-2">
            <div className="otp-header">
              <div className="back" onClick={() => navigate("/login")}>
                <img src={BackIcon} alt="Jobify" />
              </div>
              <div>
                <b>Enter 6 digit OTP</b>
                <span>
                  We sent a verification code to{" "}
                  <span className="email-text">{email}</span>
                </span>
              </div>
            </div>
            <div
              id="otp"
              className="inputs d-flex flex-row justify-content-center mt-4 gap-3"
            >
              <input
                className="text-center form-control rounded"
                type="text"
                autocomplete="off"
                id="first"
                maxLength="1"
                value={otp.first}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <input
                className="text-center form-control rounded"
                type="text"
                autocomplete="off"
                id="second"
                maxLength="1"
                value={otp.second}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <input
                className="text-center form-control rounded"
                type="text"
                autocomplete="off"
                id="third"
                maxLength="1"
                value={otp.third}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <input
                className="text-center form-control rounded"
                type="text"
                autocomplete="off"
                id="fourth"
                maxLength="1"
                value={otp.fourth}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <input
                className="text-center form-control rounded"
                type="text"
                autocomplete="off"
                id="fifth"
                maxLength="1"
                value={otp.fifth}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <input
                className="text-center form-control rounded"
                type="text"
                autocomplete="off"
                id="sixth"
                maxLength="1"
                value={otp.sixth}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="mt-4">
              <p className="text-muted">
                By Logging I agree to the
                <a className="policy-link" href="/terms-and-conditions">
                  &nbsp; Terms of Use &nbsp;
                </a>
                and
                <a className="policy-link" href="/privacy">
                  &nbsp; Privacy Policy.
                </a>
              </p>
            </div>

            <div className="mt-4">
              {/* <button
                type="button"
                className="btn btn-primary"
                onClick={sendOtpRequest}
                disabled={isLoading}
                style={{ width: "100%", marginLeft: 0 }}
              >
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <span className="ms-2">Loading...</span>
                  </div>
                ) : (
                  "Continue"
                )}
              </button> */}
            </div>

            <div className="link">
              <button
                className="otp-continue text-wrapper-4 w-100"
                onClick={sendOtpRequest}
                disabled={
                  isLoading ||
                  !otp.first ||
                  !otp.second ||
                  !otp.third ||
                  !otp.fourth ||
                  !otp.fifth ||
                  !otp.sixth
                }
                style={{ width: "100%", marginLeft: 0, height: 52 }}
              >
                {isLoading ? <Loader /> : "Continue"}
              </button>
            </div>
          </div>
        </div>

        <div className="mt-4 align-self-start">
          <p className="text-start">
            Having any trouble? &nbsp;
            <a
              href="https://jobify.club/contact"
              target="_blank"
              className="contact-us-text"
            >
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
