import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import {
  updateQuestions,
  submittedQuestions,
  updateQuestionIds,
} from "../../redux/slices/questions/questionSlice";
import { fetchQuestions } from "../../redux/thunks/questionThunks";
import Loader from "../common/Loader";
import DropdownFilter from "../common/DropdownFilter";
import solvedQuestionRight from "../../assets/images/solvedQuestionRight.svg";
import clockIcon from "../../assets/images/clockIconVieAllQuestionPage.svg";
import rightPaginationArrow from "../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../assets/images/leftPaginationArrow.svg";
import { useEffect, useMemo, useState } from "react";
const quizzesPerPage = 10;

const QuesListingPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allQuestions, attemptedQuestions, status, error } = useSelector(
    (state) => state.questions
  );
  const location = useLocation();

  const testId = searchParams.get("testId");
  console.log(allQuestions);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionType, setQuestionType] = useState("All");
  const [questionStatus, setQuestionStatus] = useState("All");
  const [currentPageQn, setCurrentPageQn] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });

  const questionStatusValues = ["All", "Solved", "Solve"];
  const questionTypeValues = ["All", "Coding", "MCQ"];

  const ifDataIsAvailable = () => {
    let tempFilteredQuestions = allQuestions.finalQuestions;

    if (questionType === "MCQ") {
      tempFilteredQuestions = tempFilteredQuestions.filter(
        (elem) => elem.options
      );
    } else if (questionType === "Coding") {
      tempFilteredQuestions = tempFilteredQuestions.filter(
        (elem) => !elem.options
      );
    }

    if (questionStatus === "Solved") {
      tempFilteredQuestions = tempFilteredQuestions.filter((elem) =>
        attemptedQuestions?.hasOwnProperty(elem.id)
      );
    } else if (questionStatus === "Solve") {
      tempFilteredQuestions = tempFilteredQuestions.filter(
        (elem) => !attemptedQuestions?.hasOwnProperty(elem.id)
      );
    }

    setFilteredQuestions(tempFilteredQuestions);
    setCurrentPageQn(tempFilteredQuestions.slice(0, quizzesPerPage));
    setCurrentPage(1);
    setButtonRange({ start: 1, end: 10 });
    setIsLoading(false);
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchQuestions(testId));
    } else if (status === "loading") {
      setIsLoading(true);
    } else if (status === "succeeded") {
      ifDataIsAvailable();
    } else if (error) {
      toast.error("Please contact us at hello@jobify.club");
    }
  }, [status, error]);

  const currentPageQuestionupdate = () => {
    const startingQn = (currentPage - 1) * quizzesPerPage;
    const tempQn = filteredQuestions?.slice(
      startingQn,
      startingQn + quizzesPerPage
    );
    setCurrentPageQn(tempQn);
  };

  const endTest = () => {
    confirmAlert({
      title: "Submit Test",
      message: "Are you sure to end test now? This cant be undone.",
      buttons: [
        {
          label: "End Test",
          onClick: async () => {
            await axiosInstance.post(
              `/api/v3/job-applications/${allQuestions.jobApplicationId}/end-test`
            );
            navigate(`/`);
          },
        },
        { label: "Cancel" },
      ],
    });
  };

  const filterQuestionUpdate = (value) => {
    setQuestionType(value);
  };

  const filterQuestionStatus = (value) => {
    setQuestionStatus(value);
  };

  const navToQuestion = (queId) => {
    navigate(`/temp-code?testId=${testId}`, {
      state: { queId: queId.toString() },
    });
  };

  const totalPages = useMemo(
    () => Math.ceil(filteredQuestions?.length / quizzesPerPage),
    [filteredQuestions]
  );

  const handleNextRange = () => {
    if (totalPages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
    if (buttonRange.end <= currentPage) {
      const nextStart = Math.min(buttonRange.start + 1, totalPages - 10 + 1);
      const nextEnd = Math.min(buttonRange.end + 1, totalPages);
      if (currentPage < totalPages) {
        setButtonRange({ start: nextStart, end: nextEnd });
      }
    }
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (buttonRange.start >= currentPage) {
      const prevStart = Math.min(buttonRange.start - 1, totalPages - 10);
      const prevEnd = Math.min(buttonRange.end - 1, totalPages);
      if (currentPage <= buttonRange.start) {
        setButtonRange({ start: prevStart, end: prevEnd });
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = buttonRange.start; i <= buttonRange.end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? "active" : ""}
          disabled={i > Math.ceil(filteredQuestions.length / 10)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  useEffect(() => {
    currentPageQuestionupdate();
  }, [currentPage, location?.state?.redirected]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="viewAllQuestionPage">
          <div className="viewAllQestionPageHeader">
            <div className="timerClock">
              <img src={clockIcon} className="clockIcon" alt="Clock Icon" />
              <span className="clockText">12:23</span>
            </div>
          </div>
          <div className="test-listing-page-main p-5">
            <div className="d-flex px-3">
              <div className="w-75">Questions</div>
              <div className="w-25 d-flex flex-row justify-content-between">
                <div className="questionTypeSelect">
                  <DropdownFilter
                    name="questionType"
                    styleClass="viewAllQnPageDropdown"
                    dropdownName="Type"
                    dropdownOptions={questionTypeValues}
                    dropdownHandleClick={filterQuestionUpdate}
                  />
                </div>
                <div className="questionStatusSelect">
                  <DropdownFilter
                    name="questionStatus"
                    styleClass="viewAllQnPageDropdown"
                    dropdownName="Status"
                    dropdownOptions={questionStatusValues}
                    dropdownHandleClick={filterQuestionStatus}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 test-segregation-box">
              {filteredQuestions.length && currentPageQn.length
                ? currentPageQn?.map((que, i) => {
                    return (
                      <div className="code-test-card d-flex" key={i}>
                        <div className="w-75">
                          {(currentPage - 1) * 10 + i + 1}.{" "}
                          {que.title || que.description}
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-25">
                          <div className="test-page-qn-type">
                            {que.options ? "MCQ" : "Coding"}
                          </div>
                          <button
                            type="button"
                            onClick={() => navToQuestion(que.id)}
                            className="test-page-action-btn col-1 d-flex justify-content-center"
                            style={{
                              backgroundColor: attemptedQuestions[que.id]
                                ? "#404342"
                                : "#B4FF45",
                              color: attemptedQuestions[que.id]
                                ? "#868C8A"
                                : "#131514",
                            }}
                            disabled={attemptedQuestions[que.id]}
                          >
                            {attemptedQuestions[que.id] && (
                              <img
                                src={solvedQuestionRight}
                                alt="Solved Icon"
                                className="solvedTickIcon"
                              />
                            )}
                            {attemptedQuestions[que.id] ? "Solved" : "Solve"}
                          </button>
                        </div>
                      </div>
                    );
                  })
                : allQuestions.finalQuestions.length && (
                    <h1 className="text-center text-warning">
                      Please change the Filter options to get the questions
                    </h1>
                  )}
            </div>
            <div className="viewAllQuestionPagePagination">
              <div className="paginationNoBtns">
                {renderPaginationButtons()}
              </div>
              <div className="paginationNextPrevBtn">
                <button
                  className="leftPaginationArrow"
                  onClick={handlePrevRange}
                  disabled={currentPage === 1}
                >
                  <img
                    className="rightPaginationArrow"
                    src={leftPaginationArrow}
                    alt="Next Question"
                  />
                </button>
                <button
                  className="rightPaginationArrow"
                  onClick={handleNextRange}
                  disabled={currentPage === totalPages}
                >
                  <img
                    className="rightPaginationArrow"
                    src={rightPaginationArrow}
                    alt="Next Question"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="footerBar">
            <button onClick={endTest}>Submit Test</button>
          </div>
        </div>
      )}
    </>
  );
};

export default QuesListingPage;
