import React, { useEffect } from "react";
import { Dropdown } from "bootstrap";

const DropdownFilter = ({
  name,
  dropdownName,
  dropdownOptions,
  dropdownHandleClick,
  styleClass,
}) => {
  useEffect(() => {
    const dropdownElement = document.getElementById(name);
    new Dropdown(dropdownElement);
  }, []);

  return (
    <div className={`${styleClass} dropdown`}>
      <button
        className="dropdown-toggle"
        type="button"
        id={name}
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {dropdownName}
      </button>
      <div className="dropdown-menu" aria-labelledby={`#name`}>
        {dropdownOptions.length &&
          dropdownOptions.map((elem, i) => {
            return (
              <button
                className="dropdown-item"
                type="button"
                key={i}
                onClick={() => dropdownHandleClick(elem)}
              >
                {elem}
              </button>
            );
          })}
      </div>
    </div>
  );
};

export default DropdownFilter;
