import { MdFormatListBulleted } from "react-icons/md";

import AlignCenter from "../../assets/icons/align-center.svg";
import AlignLeft from "../../assets/icons/align-left.svg";
import AlignRight from "../../assets/icons/align-right.svg";
import BoldIcon from "../../assets/icons/bold-icon.svg";
import ItalicIcon from "../../assets/icons/italic-icon.svg";
import LinkIcon from "../../assets/icons/link-icon.svg";
import UnderlineIcon from "../../assets/icons/underline-icon.svg";

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD", icon: BoldIcon, isSvg: true },
  { label: "Italic", style: "ITALIC", icon: ItalicIcon, isSvg: true },
  { label: "Underline", style: "UNDERLINE", icon: UnderlineIcon, isSvg: true },
  // {
  //   label: "Strikethrough",
  //   style: "STRIKETHROUGH",
  //   icon: MdStrikethroughS,
  //   isSvg: false,
  // },
  // { label: "Monospace", style: "CODE", icon: MdCode },
];

const BLOCK_TYPES = [
  { label: "UL", style: "unordered-list-item", icon: MdFormatListBulleted },
];

const TEXT_ALIGN_TYPES = [
  { label: "Left", style: "left", icon: AlignLeft, isSvg: true },
  { label: "Center", style: "center", icon: AlignCenter, isSvg: true },
  { label: "Right", style: "right", icon: AlignRight, isSvg: true },
];

const Toolbar = ({
  editorState,
  onToggle,
  onToggleBlock,
  onAlignText,
  onUndo,
}) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const currentBlockData = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getData();

  const textAlignment = currentBlockData.get("textAlignment") || "left";

  return (
    <div className="toolbar">
      {INLINE_STYLES.map((type) => {
        const Icon = type.icon;
        return (
          <button
            key={type.label}
            className={`toggle ${currentStyle.has(type.style) ? "active" : ""}`}
            onMouseDown={(e) => {
              e.preventDefault();
              onToggle(type.style);
            }}
          >
            {type.isSvg ? (
              <img height="22px" width="22px" alt={type.label} src={Icon} />
            ) : (
              <Icon size={16} />
            )}
          </button>
        );
      })}
      {/* {BLOCK_TYPES.map((type) => {
        const Icon = type.icon;
        return (
          <button
            key={type.label}
            className={`toggle ${type.style === blockType ? "active" : ""}`}
            onMouseDown={(e) => {
              e.preventDefault();
              onToggleBlock(type.style);
            }}
          >
            <Icon size={16} />
          </button>
        );
      })} */}
      {TEXT_ALIGN_TYPES.map((type) => {
        const Icon = type.icon;
        return (
          <button
            key={type.label}
            className={`toggle ${type.style === textAlignment ? "active" : ""}`}
            onMouseDown={(e) => {
              e.preventDefault();
              onAlignText(type.style);
            }}
          >
            {type.isSvg ? (
              <img height="22px" width="22px" alt={type.label} src={Icon} />
            ) : (
              <Icon size={16} />
            )}
          </button>
        );
      })}
      <button
        className="toggle"
        onMouseDown={(e) => {
          e.preventDefault();
          const url = window.prompt("Enter the URL of the link:");
          if (url) {
            console.log("Add link:", url);
          }
        }}
      >
        <img height="22px" width="22px" alt="link-icon" src={LinkIcon} />
      </button>
    </div>
  );
};

export default Toolbar;
