import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchQuestions = createAsyncThunk(
  "questions/fetchQuestions",
  async (testId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://api.assessmentpad.com/api/v3/coding-tests/${testId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
