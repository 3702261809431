import AppNav from "./AppNav";
import "./TestPermissionPage.css";
import {
  AiOutlineDesktop,
  AiFillCamera,
  AiFillAudio,
  AiFillSafetyCertificate,
} from "react-icons/ai";
import GrantPermissions from "./GrantPermissions";

export default function TestPermissionPage() {
  return (
    <>
      <AppNav />
      <div className="per-main-body">
        <div>
          <p className="permissions-page-head">
            We require permissions to proceed
          </p>
          <p className="per-sub-head">
            Having any trouble?{" "}
            <span>
              <a href="mailto: hello@jobify.club"> Reach out to us</a>
            </span>
          </p>
        </div>
        <div className="per-block-body">
          <div className="d-flex">
            <AiOutlineDesktop className="per-block-icon" />
            <div className="per-block">
              <p className="per-block-head">Window management</p>
              <p className="per-block-content">
                Required to make sure there are no extended screens
              </p>
            </div>
          </div>
          <div className="d-flex">
            <AiFillCamera className="per-block-icon" />
            <div className="per-block">
              <p className="per-block-head">Enable access to camera</p>
              <p className="per-block-content">
                Required to capture live actions of candidate for fair
                evaluation
              </p>
            </div>
          </div>
          <div className="d-flex">
            <AiFillAudio className="per-block-icon" />
            <div className="per-block">
              <p className="per-block-head">Enable access to microphone</p>
              <p className="per-block-content">
                Required to record candidate voice recording
              </p>
            </div>
          </div>
        </div>
        <div className="per-terms-body">
          <p className="per-terms-head">
            <AiFillSafetyCertificate className="per-safe-icon" />
            Our proctoring steps for ensuring a violation-free screening :
          </p>
          <ul className="per-terms-content">
            <li>
              {" "}
              Ensures there are no multiple screens connected to your primary
              device.{" "}
            </li>
            <li>
              Tracking refreshes, change of tabs or window during the screening
            </li>
            <li>
              Monitor any suspicious eye and body movements, along with any
              external sounds besides your own
            </li>
            <li>
              Please ensure that your face, hands, and desk are clearly visible
              during the assessment. Aim to have more than half of your body in
              view on camera
            </li>
          </ul>
        </div>
      </div>
      <GrantPermissions />
    </>
  );
}
