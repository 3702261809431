import React, { useRef, useEffect } from "react";
import { Editor } from "@monaco-editor/react";

import { customLibraries } from "../../utilities/language/languageUtilities";
import { registerLanguageProvider } from "../../utilities/language/languageProvider";
import { useAppSelector } from "../../redux/hooks";
import Loader from "../common/Loader";
import {
  configureJavaScriptLanguage,
  configureTypeScriptLanguage,
} from "../../editor/jstsConfig";

const CodeEditor = ({ language, sourceCode, setSourceCode }) => {
  const editorRef = useRef(null);
  const monacoRef = useRef(null);
  const { theme } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!monacoRef.current) return;

    const monaco = monacoRef.current;

    const disposable = registerLanguageProvider(
      monaco,
      language,
      customLibraries[language]
    );

    return () => {
      disposable.dispose();
    };
  }, [language]);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    monacoRef.current = monaco;

    switch (language.toLowerCase()) {
      case "javascript":
        configureJavaScriptLanguage(monaco);
        break;
      case "typescript":
        configureTypeScriptLanguage(monaco);
        break;
      default:
        break;
    }

    // Set initial language mode
    monaco.editor.setModelLanguage(editor.getModel(), language);

    // Set up validation on content changes
    const model = editor.getModel();
    if (model) {
      model.onDidChangeContent(() => {
        monaco.editor.setModelMarkers(model, language, []);
        monaco.languages.diagnostics.trigger(language, model.uri);
      });
    }
  };

  const handleValidate = (markers) => {
    markers.forEach((marker) => {
      console.log(marker, "onValidate:", marker.message);
    });
  };

  return (
    <Editor
      height="100%"
      language={language}
      theme={theme === "light" ? "" : "vs-dark"}
      defaultLanguage={language}
      value={sourceCode}
      onChange={setSourceCode}
      onValidate={handleValidate}
      loading={<Loader />}
      onMount={handleEditorDidMount}
      options={{
        minimap: { enabled: false },
        scrollbar: {
          vertical: "auto",
          horizontal: "auto",
        },
      }}
    />
  );
};

export default CodeEditor;
