import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { submittedQuestions } from "../../redux/slices/questions/questionSlice";

const MCQPage = ({ data, testId }) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selector = useSelector((state) => state.questions);
  const dispatch = useDispatch();

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (selectedValue.includes(value)) {
      setSelectedValue(selectedValue.filter((item) => item !== value)); // Remove the value if it's already selected
    } else {
      setSelectedValue([...selectedValue, value]); // Add the value if it's not selected
    }
  };

  const submitAnswer = () => {
    setIsLoading(true);
    axiosInstance
      .post(`api/v3/coding-tests/${testId}/mcq/${data.id}/submit`, {
        selectedOptionIdList: selectedValue,
      })
      .then(() => {
        dispatch(
          submittedQuestions({
            [data.id.toString()]: [...selectedValue],
          })
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="test-page-main">
      <div className="mcqQuestionPanel">
        <div className="w-50 mcqQuestionDesc">{data.description}</div>
        <ul className="macq-radio-grid mcqOptions w-50">
          <span className="mcqOptionItemsTitle">SELECT ONLY ONE</span>
          {data.options.map((opt, i) => {
            return (
              <li key={i} className="mcqOptionItems w-75">
                <input
                  type={`${
                    data.type === "SINGLE_SELECTION" ? "radio" : "checkbox"
                  }`}
                  name={opt.mcq_id}
                  value={opt.id}
                  checked={selectedValue.includes(opt.id)}
                  onChange={(val) => {
                    data.type === "SINGLE_SELECTION"
                      ? setSelectedValue([val.target.value])
                      : handleCheckboxChange(val);
                  }}
                  disabled={selector.attemptedQuestions[data.id]}
                />
                <label htmlFor={opt.mcq_id}>{opt.content}</label>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="bottom-action-panel">
        <button
          type="button"
          disabled={!selectedValue.length}
          className="action-btn"
          onClick={submitAnswer}
        >
          {selector.attemptedQuestions[data.id]
            ? " Submitted Successfully"
            : " Submit"}
          {isLoading && (
            <div
              className="spinner-border spinner-border-sm ml-2"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default MCQPage;
