// Common compiler options for both JavaScript and TypeScript
const getBaseCompilerOptions = (monaco) => ({
  target: monaco.languages.typescript.ScriptTarget.Latest,
  allowNonTsExtensions: true,
  moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
  module: monaco.languages.typescript.ModuleKind.CommonJS,
  noEmit: true,
  esModuleInterop: true,
  jsx: monaco.languages.typescript.JsxEmit.React,
  typeRoots: ["node_modules/@types"],
});

// Common snippets shared between JavaScript and TypeScript
const getBaseSnippets = (monaco) => [
  {
    label: 'for',
    kind: monaco.languages.CompletionItemKind.Snippet,
    insertText: 'for (let ${1:i} = 0; ${1:i} < ${2:length}; ${1:i}++) {\n\t${0}\n}',
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    documentation: 'For Loop'
  },
  {
    label: 'while',
    kind: monaco.languages.CompletionItemKind.Snippet,
    insertText: 'while (${1:condition}) {\n\t${0}\n}',
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    documentation: 'While Loop'
  },
];

// Register completion provider for a specific language
const registerCompletionProvider = (monaco, language, snippets) => {
  monaco.languages.registerCompletionItemProvider(language, {
    provideCompletionItems: () => ({ suggestions: snippets })
  });
};

export const configureJavaScriptLanguage = (monaco) => {
  // JavaScript IntelliSense configuration
  monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false,
  });

  monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    ...getBaseCompilerOptions(monaco),
    allowJs: true,
  });

  const jsSnippets = [
    ...getBaseSnippets(monaco),
    {
      label: 'do',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertText: 'do {\n\t${0}\n} while (${1:condition});',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Do-While Loop'
    }
  ];

  registerCompletionProvider(monaco, 'javascript', jsSnippets);
};

export const configureTypeScriptLanguage = (monaco) => {
  // TypeScript IntelliSense configuration
  monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false,
  });

  monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
    ...getBaseCompilerOptions(monaco),
    strict: true,
  });

  const tsSnippets = [
    ...getBaseSnippets(monaco),
    {
      label: 'interface',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertText: 'interface ${1:Name} {\n\t${0}\n}',
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Interface declaration'
    }
  ];

  registerCompletionProvider(monaco, 'typescript', tsSnippets);
}; 