import React, { useState, useRef, useEffect } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  ContentBlock,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "./styles.css";
import Toolbar from "./Toolbar";

const { hasCommandModifier } = KeyBindingUtil;

export default function RichTextEditor() {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const editor = useRef(null);

  useEffect(() => {
    if (editor.current) {
      editor.current.focus();
    }
  }, []);

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const alignText = (alignment) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);

    const newContentState = Modifier.mergeBlockData(contentState, selection, {
      textAlignment: alignment,
    });

    setEditorState(
      EditorState.push(editorState, newContentState, "change-block-data")
    );
  };

  const undo = () => {
    setEditorState(EditorState.undo(editorState));
  };

  const blockStyleFn = (contentBlock) => {
    const textAlignment = contentBlock.getData().get("textAlignment");
    if (textAlignment) {
      return `text-align-${textAlignment}`;
    }
    return "";
  };

  return (
    <>
      <div className="rich-text-editor">
        <div
          className="editor-container"
          onClick={() => editor.current?.focus()}
        >
          <Editor
            ref={editor}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={setEditorState}
            placeholder="Enter some text..."
            spellCheck={true}
            blockStyleFn={blockStyleFn}
          />
        </div>
      </div>
      <Toolbar
        editorState={editorState}
        onToggle={toggleInlineStyle}
        onToggleBlock={toggleBlockType}
        onAlignText={alignText}
        onUndo={undo}
      />
    </>
  );
}
