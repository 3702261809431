import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthStatus, setTheme } from "../redux/slices/auth/authSlice";
import Loader from "./common/Loader";
import AssessmentPadLogo from "./AssessmentPadLogo";

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecruite, setIsRecruite] = useState(true);

  const [activeTab, setActiveTab] = useState("candidate");
  const { isAuthenticated, token } = checkAuthStatus();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem("role");

    if (isAuthenticated && token && role === "candidate") {
      navigate("/candidates-view");
    } else {
      dispatch(setTheme("light"));
    }
  }, [navigate, token]);

  const handleTabSwitch = (tab) => {
    console.log(tab);
    setActiveTab(tab);
    console.log(activeTab);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  function setvalue() {
    if (activeTab === "recruiter") {
      setIsRecruite(true);
    } else {
      setIsRecruite(false);
    }
  }
  const handleContinue = () => {
    setIsLoading(true);
    console.log(activeTab);
    // setvalue()

    console.log("hello");
    console.log(isRecruite);
    if (validateEmail(email)) {
      axiosInstance
        .post(
          `v1/apis/login/otp?email=${encodeURIComponent(email)}&isRecruiter=${
            activeTab === "recruiter" ? true : false
          }`
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setIsLoading(false);

            // alert("Form submitted!");
            // window.location.href = '/otp';
            handleNavigation();
          } else {
            // alert("Failed to submit form. Please try again.");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(
            "You are not a recruiter partner with us yet! Please contact hello@jobify.club"
          );
          setEmail("");
          console.error("Error:", error);
        });
    } else {
      setIsLoading(false);

      setEmailError(true);
    }
  };
  const handleNavigation = () => {
    navigate("/otp", { state: { role: activeTab, email: email } });
  };

  const isEnterPressed = (event) => {
    return event.code === "Enter" || event.code === "NumpadEnter";
  };

  useEffect(() => {
    const listener = (event) => {
      if (isEnterPressed(event)) {
        event.preventDefault();
        handleContinue();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, activeTab]);

  return (
    <div className="login-container" data-colors-mode="dark">
      <div className="login-box">
        <div className="frame">
          <a href="/" className="jobify-image">
            <AssessmentPadLogo />
          </a>
          <div className="div">
            <div className="text-wrapper">Login</div>
            <div
              className="div-3 p-1"
              style={{ backgroundColor: "#333333", borderRadius: "2rem" }}
            >
              <div className="d-flex w-100">
                <nav variant="tabs" className="custom-tabs">
                  {/* <Nav.Item> */}
                  <Nav.Link
                    style={{
                      borderRadius: "2rem",
                      color: "#868C8A !important",
                    }}
                    eventKey="#candidate"
                    className={
                      activeTab === "candidate"
                        ? "active custom-tab"
                        : "custom-tab"
                    }
                    onClick={() => handleTabSwitch("candidate")}
                  >
                    Candidate
                  </Nav.Link>
                  <Nav.Link
                    style={{
                      borderRadius: "2rem",
                      color: "#868C8A !important",
                    }}
                    eventKey="#recruiter"
                    className={
                      activeTab === "recruiter"
                        ? "active custom-tab"
                        : "custom-tab"
                    }
                    onClick={() => handleTabSwitch("recruiter")}
                  >
                    Recruiter
                  </Nav.Link>
                  {/* </Nav.Item> */}
                  {/* <Nav.Item> */}

                  {/* </Nav.Item> */}
                </nav>
              </div>
            </div>
            <div className="div-3 w-100">
              <div className="text-wrapper-2">Email*</div>
              <div className="div-4 w-100">
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className={emailError ? "error" : ""}
                  placeholder="Enter your email"
                />
                {emailError && (
                  <div className="text-wrapper-3">Invalid email format</div>
                )}
              </div>
            </div>
            <div className="link">
              <button
                style={{ height: 52 }}
                className="text-wrapper-4 w-100"
                onClick={handleContinue}
              >
                {isLoading ? <Loader /> : "Continue"}
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 align-self-start">
          <p className="text-start">
            Having any trouble? &nbsp;
            <a
              href="https://jobify.club/contact"
              target="_blank"
              className="contact-us-text"
            >
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
