export const Language = {
    JAVASCRIPT: 'JAVASCRIPT',
    PYTHON2: 'PYTHON2',
    PYTHON3: 'PYTHON3',
    JAVA: 'JAVA',
    CPP: 'CPP',
    PHP: 'PHP',
    SCALA: 'SCALA',
    TYPESCRIPT: 'TYPESCRIPT',
    RUST: 'RUST',
    RUBY: 'RUBY',
    GOLANG: 'GOLANG'
}

export const languageIdMapping = {
    63: Language.JAVASCRIPT,
    70: Language.PYTHON2,
    71: Language.PYTHON3,
    62: Language.JAVA,
    54: Language.CPP,
    68: Language.PHP,
    81: Language.SCALA,
    74: Language.TYPESCRIPT,
    73: Language.RUST,
    72: Language.RUBY,
    60: Language.GOLANG
}

const javascriptSnippets = {
  console: {
    log: '(message: any) => void',
    error: '(message: any) => void',
    warn: '(message: any) => void',
  },
  // Common JavaScript constructs
  'for': {
    description: 'For loop',
    snippet: 'for (let ${1:i} = 0; ${1:i} < ${2:length}; ${1:i}++) {\n\t${3}\n}',
  },
  'while': {
    description: 'While loop',
    snippet: 'while (${1:condition}) {\n\t${2}\n}',
  },
  'do': {
    description: 'Do-while loop',
    snippet: 'do {\n\t${1}\n} while (${2:condition});',
  },
  'if': {
    description: 'If statement',
    snippet: 'if (${1:condition}) {\n\t${2}\n}',
  },
  'function': {
    description: 'Function declaration',
    snippet: 'function ${1:name}(${2:params}) {\n\t${3}\n}',
  },
  'forEach': {
    description: 'Array forEach loop',
    snippet: '${1:array}.forEach((${2:item}) => {\n\t${3}\n});',
  },
  'map': {
    description: 'Array map',
    snippet: '${1:array}.map((${2:item}) => {\n\t${3}\n});',
  }
};

export const customLibraries = {
    java: {
      'CustomJavaClass': {
        description: 'A custom Java class for this project',
      },
      'customJavaMethod': {
        description: 'A custom Java method for this project',
      },
      'PROJECT_CONSTANT': {
        description: 'A constant specific to this Java project',
      },
      'DatabaseConnection': {
        description: 'A custom class for database connections',
      },
      'ApiClient': {
        description: 'A custom class for making API calls',
      },
      'LoggerUtil': {
        description: 'A utility class for logging',
      },
      'ConfigManager': {
        description: 'A class for managing application configurations',
      },
    },
    javascript: {
      globals: javascriptSnippets
    },
    typescript: {
      globals: {
        // TypeScript-specific features
        'interface': {
          description: 'Interface declaration',
          snippet: 'interface ${1:Name} {\n\t${2}\n}',
        },
        'type': {
          description: 'Type alias declaration',
          snippet: 'type ${1:Name} = ${2:Type};',
        },
        'enum': {
          description: 'Enum declaration',
          snippet: 'enum ${1:Name} {\n\t${2}\n}',
        },
        // Include all JavaScript snippets
        ...javascriptSnippets
      }
    }
}
  
  