import { confirmAlert } from "react-confirm-alert";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

import rightPaginationArrow from "../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../assets/images/leftPaginationArrow.svg";
import rightImage from "../../assets/images/CompletedQuestionRight.svg";
import viewAllQuestions from "../../assets/images/viewAllQuestions.svg";
import clockIcon from "../../assets/images/clockIcon.svg";
import testStopIcon from "../../assets/images/testStopBtn.svg";

const EditorPagination = ({ onQueChange, activeQue }) => {
  const navigate = useNavigate();
  const selector = useSelector((state) => state.questions);
  const totalQuestions = selector.allQuestions.finalQuestions.length;
  let currentIndex = selector.allQuestions.finalQuestions.findIndex(
    (elem) => elem.id === activeQue
  );
  const [rightEnd, setRightEnd] = useState(false);
  const [leftEnd, setLeftEnd] = useState(currentIndex === 0);

  const handlePrevious = () => {
    let previousQnIndex = currentIndex;
    if (currentIndex > 0) {
      previousQnIndex = currentIndex - 1;
      while (
        selector.attemptedQuestions[
          selector.allQuestions.finalQuestions[previousQnIndex].id
        ] &&
        previousQnIndex > 0
      ) {
        previousQnIndex--;
      }
      if (previousQnIndex >= 0) {
        currentIndex = previousQnIndex;
      }
    }
    if (
      selector.attemptedQuestions[
        selector.allQuestions.finalQuestions[currentIndex].id
      ]
    ) {
      onQueChange(selector.allQuestions.finalQuestions[currentIndex].id);
    } else {
      skipQnCnfirmation(currentIndex);
    }
    setLeftEnd(currentIndex === 0);
    setRightEnd(false);
  };

  const handleNext = () => {
    let nextIndex = currentIndex;
    if (currentIndex < totalQuestions - 1) {
      nextIndex = currentIndex + 1;
      while (
        selector.attemptedQuestions[
          selector.allQuestions.finalQuestions[nextIndex].id
        ] &&
        nextIndex < totalQuestions - 1
      ) {
        nextIndex++;
      }
      if (nextIndex <= totalQuestions - 1) {
        currentIndex = nextIndex;
      }
    }
    if (
      selector.attemptedQuestions[
        selector.allQuestions.finalQuestions[currentIndex].id
      ]
    ) {
      onQueChange(selector.allQuestions.finalQuestions[currentIndex].id);
    } else {
      skipQnCnfirmation(currentIndex);
    }
    setRightEnd(currentIndex === totalQuestions - 1);
    setLeftEnd(false);
  };

  const getCurrentIndex = () => {
    currentIndex = selector.allQuestions.finalQuestions.findIndex(
      (q) => q.id === activeQue
    );

    let start = Math.max(0, Math.min(currentIndex, totalQuestions - 10));
    let end = Math.min(start + 10, totalQuestions);

    return selector.allQuestions.finalQuestions.slice(start, end);
  };

  const skipQnCnfirmation = (index) => {
    if (!selector.attemptedQuestions[selector.allQnIDs[index]]) {
      confirmAlert({
        title: "Are you sure you want to skip this question ?",
        message:
          "You cannot come back to this question later. Even if you don't know the answer, just pick the answer you feel best about. You might get it right!",
        buttons: [
          {
            label: "Skip question",
            className: "btnSkipOutline",
            onClick: async () => {
              onQueChange(selector.allQuestions.finalQuestions[index].id);
            },
          },
          { label: "Choose answer", className: "btnSkipPrimary" },
        ],
      });
    }
  };

  const endTest = () => {
    confirmAlert({
      title: "Submit Test",
      message: "Are you sure to end test now? This cant be undone.",
      buttons: [
        {
          label: "End Test",
          onClick: async () => {
            await axiosInstance.post(
              `/api/v3/job-applications/${selector.allQuestions.jobApplicationId}/end-test`
            );
            navigate(`/`);
          },
        },
        { label: "Cancel" },
      ],
    });
  };

  const visibleQuestions = getCurrentIndex();

  return (
    <div className="d-flex align-center justify-content-between test-pagiation test-header">
      <div
        className="view-all-btn tw-cursor-pointer"
        onClick={() =>
          navigate(`/main-test?testId=${selector.allQuestions.id}`, {
            state: { redirected: true },
          })
        }
      >
        <img
          src={viewAllQuestions}
          className="viewAllQuestionsIcon"
          alt="View All Questions Icon"
        />
        <span
          style={{
            color: "#868C8A",
            textDecorationColor: "#868C8A",
            borderBottom: "solid 1px",
          }}
        >
          View all Question List
        </span>
      </div>
      <div className="test-pagination">
        <button
          className="test-pagination-arrow-btn"
          onClick={handlePrevious}
          disabled={leftEnd}
        >
          <img
            className="leftPaginationArrow"
            src={leftPaginationArrow}
            alt="Previous Question"
          />
        </button>
        <div className="test-pagination-btn-group">
          {visibleQuestions.map((stage) => {
            const actualIndex = selector.allQuestions.finalQuestions.findIndex(
              (q) => q.id === stage.id
            );
            return (
              <button
                key={stage.id}
                className={`test-pagination-btn ${
                  stage.id === activeQue ? "active" : ""
                }`}
                onClick={() => {
                  if (selector.attemptedQuestions[activeQue]) {
                    onQueChange(stage.id);
                  } else if (stage.id !== activeQue) {
                    skipQnCnfirmation(
                      selector?.allQuestions?.finalQuestions?.findIndex(
                        (index) => index.id === stage.id
                      )
                    );
                  }
                }}
                style={{
                  backgroundColor: selector.attemptedQuestions[stage.id]
                    ? "#393A3A"
                    : "#191C1B",
                }}
                disabled={selector.attemptedQuestions[stage.id]}
              >
                {selector.attemptedQuestions[stage.id] && (
                  <span className="submittedQnFlag">
                    <img
                      className="rightImage"
                      src={rightImage}
                      alt="Completed"
                    />
                  </span>
                )}
                {actualIndex + 1}
              </button>
            );
          })}
        </div>
        <button
          className="test-pagination-arrow-btn"
          onClick={handleNext}
          disabled={rightEnd}
        >
          <img
            className="rightPaginationArrow"
            src={rightPaginationArrow}
            alt="Next Question"
          />
        </button>
      </div>
      <div className="testTimerEnd">
        <div className="timerClock">
          <img src={clockIcon} className="clockIcon" alt="Clock Icon" />
          <span className="clockText">12:23</span>
        </div>
        <button type="button" className="testStopBtn" onClick={endTest}>
          <img
            src={testStopIcon}
            className="testStopIcon"
            alt="Stop Test Icon"
          />
          <span>End Test</span>
        </button>
      </div>
    </div>
  );
};

export default EditorPagination;
