import { ClipLoader } from 'react-spinners';

const Loader = ({ size = 50, color = "#3498db" }) => {
  return (
    <div className="loader-container">
      <ClipLoader color={color} loading={true} size={size} />
    </div>
  );
};

export default Loader; 