import React, { useRef, useState } from "react";
import RecordContext from "./recordContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import axiosInstance from "../axiosInstance";
import { useAppSelector } from "../redux/hooks";
import { toast } from "react-toastify";

const RecordProvider = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useAppSelector(state => state.auth);

  const handleNavigation = () => {
    navigate("/submissions");
  };

  const mainVideoRef = useRef(null);
  const camVideoRef = useRef(null);
  const mediaRecorderRef = useRef(null); // This ref will store the mediaRecorder instance

  let recordedChunks = [];
  const [isRecording, setIsRecording] = useState(false);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [streams, setStreams] = useState({});

  const captureScreen = async () => {
    let captureStream = null;
    try {
      captureStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          displaySurface: "monitor",
          mediaSource: 'screen'
        }
      });

      const settings = captureStream.getVideoTracks()[0].getSettings();

      captureStream.getVideoTracks()[0].onended = () => {
        console.log('>>>>> SCREEN CAPTURE ENDED >>>>>>>>');
        console.log(camVideoRef.current.srcObject);
        camVideoRef.current.srcObject.getTracks().forEach(track => track.stop())
      }

      if(settings.displaySurface === 'monitor') {
        setStreams((prev) => ({ ...prev, screen: captureStream }));
        mainVideoRef.current.srcObject = captureStream;
        console.log('>>>>> SCREEN CAPTURED SUCCESSFULLY >>>>>>>>');
        return "SUCCESS";
      } else {
        throw 'SHARE_ENTIRE_SCREEN'
      }
    } catch (error) {
      console.log('>>>>>> SCREEN CAPTURED FAILED >>>>>>>', error);
      if(error === 'SHARE_ENTIRE_SCREEN') {
        toast.error("Kindly share entire screen");
      } else {
        toast.error("Failed to share screen");
      }
      return "FAIL";
    }

  };

  const captureUserMedia = async () => {
    try {
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStreams((prev) => ({ ...prev, camera: userStream }));
      userStream.getVideoTracks()[0].onended = () => {
        console.log('>>>>> USER MEDIA ENDED >>>>>>>>');
      }
      camVideoRef.current.srcObject = userStream;
      setIsVideoRecording(true);
      console.log('>>>>> USER MEDIA CAPTURED SUCCESSFULLY >>>>>>>>');
      return 'SUCCESS'
    } catch (error) {
      console.log('>>>>>> USER MEDIA CAPTURED FAILED >>>>>>>');
      toast.error("Kindly enable camera and microphone settings");
      return "FAIL";
    }


  };

  const startMicrophone = async () => {
    try {
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setStreams((prev) => ({ ...prev, microphone: micStream }));
    } catch (error) {
      toast.error("Please provide microphone permission");
      return "fail";
    }
  };

  const uploadChuksVideo = async (chunks) => {
    try {
      const url = `/v1/video/publish?emailId=${user.email}&jobId=${searchParams.get('jobId')}`;
      const formData = new FormData();
      formData.append("file", chunks);
      console.log(url);
      const response = await axiosInstance.post(url, formData);
      console.log(response.data);
    } catch (error) {
    }
  }


  const startRecording = () => {
    try {
      const combinedTracks = Object.values(streams).flatMap((s) => s.getTracks());
      const combinedStream = new MediaStream(combinedTracks);

      mediaRecorderRef.current = new MediaRecorder(combinedStream);
      console.log("MediaRecorder initialized", mediaRecorderRef.current.state);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          uploadChuksVideo(event.data);
          // recordedChunks.push(event.data);
          /*
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";

          let url = window.URL.createObjectURL(event.data);
          a.href = url;
          a.download = `video-${Math.random()}.webm`; // Give the file a name
          a.click();
          window.URL.revokeObjectURL(url);
          */
        }
      };

      mediaRecorderRef.current.onstop = uploadVideo;
      mediaRecorderRef.current.start(30000);

      console.log("After start called:", mediaRecorderRef.current.state);
      setIsRecording(true);
      return 'SUCCESS';
    } catch (error) {
      toast.error("Error in Recording");
      return "FAIL";
    }
  };

  const stopAllStreams = () => {
    Object.values(streams).forEach((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    });
    if (mainVideoRef.current) mainVideoRef.current.srcObject = null;
    if (camVideoRef.current) camVideoRef.current.srcObject = null;
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      stopAllStreams();
      setStreams({});
      handleNavigation();
    } else {
      console.error(
        "Recording has not started or mediaRecorder is not initialized."
      );
    }
  };

  const uploadVideo = async () => {
    console.log("upload")
    const blob = new Blob(recordedChunks, { type: "video/webm" });
    console.log(localStorage.getItem("jobId"));
    console.log(localStorage.getItem("email"));
    let email = JSON.parse(localStorage.getItem("email")); // This will remove quotes
    let jobId = localStorage.getItem("jobId");
    const authData = JSON.parse(localStorage.getItem("auth"));
    const token = authData; // This will remove quotes
    let details = `N/A:N/A:${email}:N/A:${jobId}:N/A:N/A:N/A:N/A:N/A`;
    let encoded = base64_encode(details);
    // const filename = `${jobId}:${email}.webm`;

    const formData = new FormData();
    // formData.append("jobId", localStorage.getItem("jobId"));
    // formData.append("email", email);
    formData.append("file", blob);

    try {
      const response = await fetch(
        // "https://jobify-upload.onrender.com/upload",
        `https://jobify-backend-wrapper-7323fa1818eb.herokuapp.com/v1/jobify/apis/candidates/jobs/apply?encodedDetails=${encoded}&token=${token}&isResumeUpload=false`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (data) {
        console.log(data.filePath);
        console.log(data);
        localStorage.removeItem("jobId");
        return "fail";
      }
    } catch (error) {
      console.error("Failed to upload video:", error);
    }
  };

  // Toggle PiP function
  const togglePiP = async () => {
    try {
      if (document.pictureInPictureEnabled) {
        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
          return 'SUCCESS';
        } else {
          if (camVideoRef.current) {
            await camVideoRef.current.requestPictureInPicture();
            return 'SUCCESS';
          }
        }
      } else {
        new Error("Picture-in-Picture is not supported by this browser.")
        alert("Picture-in-Picture is not supported by this browser.");
      }
    } catch (error) {
      console.log('>>>>>> PIP FAILED >>>>>>>', error);
      return "FAIL";
    }
  };

  return (
    <RecordContext.Provider
      value={{
        mainVideoRef,
        camVideoRef,
        isRecording,
        isVideoRecording,
        captureScreen,
        captureUserMedia,
        startMicrophone,
        startRecording,
        stopRecording,
        togglePiP,
      }}
    >
      {props.children}
    </RecordContext.Provider>
  );
};
export default RecordProvider;
