import React, { useState } from "react";
import AppInput from "../AppInput";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./data";
import RefreshIcon from "../../assets/images/refresh.png";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const CandidateDetails = () => {
  const [file, setFile] = useState();
  const navigate = useNavigate();
  const savedEmail = JSON.parse(localStorage.getItem("email")) || "";
  const tempjobId = localStorage.getItem("jobId");
  const jobId = tempjobId ? tempjobId : null;

  if (!savedEmail) {
    navigate("/login");
  }
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: savedEmail,
    role: "candidate",
    _token: "",
    expires_at: "",
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const submitHanlder = () => {
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    if (jobId) {
      navigate(`/test-arena?jobId=${jobId}`);
    } else {
      navigate("/candidates-view");
    }
  };
  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  return (
    <div className="resume_upload_container">
      <section className="input_form">
        <form onSubmit={submitHanlder}>
          <h5>User Details</h5>
          <div className="sub-heading">
            An updated resume is key to being shortlisted
          </div>
          <div className="tw-flex tw-flex-wrap tw-gap-y-4 tw-my-4">
            <div className="sm:tw-w-1/2 tw-pr-4">
              <AppInput
                name="firstName"
                label=""
                placeholder="First name*"
                register={register}
                errors={errors}
                onChange={inputChangeHandler}
                required
              />
            </div>
            <div className="sm:tw-w-1/2 tw-pr-4">
              <AppInput
                name="lastName"
                label=""
                placeholder="Last name*"
                register={register}
                errors={errors}
                onChange={inputChangeHandler}
                required
              />
            </div>
            <div className="sm:tw-w-1/2 tw-pr-4">
              <AppInput
                name="mobileNumber"
                label=""
                placeholder="Phone no*"
                register={register}
                errors={errors}
                onChange={inputChangeHandler}
                required
              />
            </div>
            <div className="sm:tw-w-1/2 tw-pr-4">
              <AppInput
                name="email"
                label=""
                placeholder="Email*"
                value={userDetails.email}
                register={register}
                onChange={inputChangeHandler}
                errors={errors}
                disabled={savedEmail.length}
                // required
              />
            </div>
          </div>
          <h6 className="tw-my-3">Upload your updated resume:</h6>
          <div className="sub-heading">
            If you don't have an updated resume, we strongly suggest you create
            one. Otherwise upload a PDF of your updated LinkedIn profile
          </div>
          <div>
            {errors?.resume && (
              <div className="error tw-ml-0.5 tw-text-sm">
                Please try again!
              </div>
            )}
            {!file ? (
              <input
                className="tw-relative tw-mt-2 file_upload"
                name="company_logo"
                type="file"
                id="file"
                onChange={(event) => setFile(event.target.files)}
                accept=".pdf"
              />
            ) : (
              <div className="uploaded_file tw-mt-3 tw-mb-6">
                <span>{file?.name}</span>
                <img
                  src={RefreshIcon}
                  alt="Update New Resume"
                  onClick={() => setFile(null)}
                  className="tw-cursor-pointer"
                />
              </div>
            )}{" "}
          </div>
          <button type="submit" className="mx-2 btn btn-main tw-w-full">
            Submit
          </button>
        </form>
      </section>
    </div>
  );
};

export default CandidateDetails;
