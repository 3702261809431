import { useState } from "react";
import { Form } from "react-bootstrap";
import { DEFAULT_JOB_DATA } from "../../../basic";
import DropdownFilter from "../../common/DropdownFilter";
import RichTextEditor from "../../RTE/RichTextEditor";
import "./createQuestion.scss";
import TestCase from "../../CodeEditor/TestCase";
import RecruiterTestCases from "../TestCases/TestCase";
import deleteIcon from "../../../assets/icons/delete.svg";

const questionTypeValues = ["Coding", "MCQ"];

export default function CreateQuestions() {
  const [questionType, setSelectedQuestionType] = useState("Coding");
  const [selectedTest, setSelectedTest] = useState("");
  const [formData, setFormData] = useState({
    ...DEFAULT_JOB_DATA,
    questionTitle: "",
  });
  const [validated, setValidated] = useState(false);
  const [mcqOptions, setMcqOptions] = useState([
    { id: 1, text: "", isCorrect: false },
    { id: 2, text: "", isCorrect: false },
    { id: 3, text: "", isCorrect: false },
    { id: 4, text: "", isCorrect: false },
  ]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      //   handleNext();
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  const handleSelectJob = (value) => {
    setSelectedQuestionType(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOptionChange = (id, value) => {
    setMcqOptions((options) =>
      options.map((option) =>
        option.id === id ? { ...option, text: value } : option
      )
    );
  };

  const handleCorrectAnswer = (id) => {
    setMcqOptions((options) =>
      options.map((option) =>
        option.id === id ? { ...option, isCorrect: !option.isCorrect } : option
      )
    );
  };

  const addNewOption = () => {
    const newId = mcqOptions.length + 1;
    setMcqOptions([...mcqOptions, { id: newId, text: "", isCorrect: false }]);
  };

  const removeOption = (id) => {
    setMcqOptions((options) => options.filter((option) => option.id !== id));
  };

  return (
    <div className="main-container">
      <div className="question-section">
        <div className="flex justify-between mb-4 filter-container">
          <div className="filter-section">
            <div>
              <div className="relative">
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#E2F0E7",
                  }}
                >
                  Create question
                </span>

                <DropdownFilter
                  name="quetionType"
                  styleClass="question-type"
                  dropdownName={questionType || "Select Category"}
                  dropdownOptions={questionTypeValues}
                  dropdownHandleClick={handleSelectJob}
                />
              </div>
            </div>
          </div>

          <Form
            className="rich-text-editor"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            {questionType === "Coding" ? (
              <>
                <div className="rte-main-container">
                  <div className="mb-4">
                    <span className="tw-mb-2 title">Question Title</span>
                    <Form.Control
                      type="text"
                      name="questionTitle"
                      value={formData.questionTitle}
                      onChange={handleInputChange}
                      placeholder="Enter question title"
                      required
                    />
                  </div>

                  <span className="title">Description</span>
                  <div className="rte-section">
                    <RichTextEditor />
                  </div>
                </div>

                <div className="test-cases-container">
                  <RecruiterTestCases />
                </div>
              </>
            ) : (
              <div className="mcq-container">
                <div className="rte-main-container">
                  <div className="mb-4">
                    <span className="title">Question Title</span>
                    <Form.Control
                      type="text"
                      name="questionTitle"
                      value={formData.questionTitle}
                      onChange={handleInputChange}
                      placeholder="Enter question title"
                      required
                    />
                  </div>

                  <span className="title">Description</span>
                  <div className="rte-section">
                    <RichTextEditor />
                  </div>
                </div>

                <div className="mcq-options-container">
                  <div className="title-row">
                    <span className="title">Select correct answer</span>
                    <span className="subtitle">(Can be more than 1)</span>
                  </div>

                  {mcqOptions.map((option) => (
                    <div key={option.id} className="option-row">
                      <input
                        type="checkbox"
                        checked={option.isCorrect}
                        onChange={() => handleCorrectAnswer(option.id)}
                        className="option-checkbox"
                      />
                      <input
                        type="text"
                        value={option.text}
                        onChange={(e) =>
                          handleOptionChange(option.id, e.target.value)
                        }
                        placeholder={`Option ${option.id}`}
                        className="option-input"
                      />
                      <button
                        className="option-delete"
                        onClick={() => removeOption(option.id)}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </button>
                    </div>
                  ))}

                  <button
                    type="button"
                    className="add-option-btn"
                    onClick={addNewOption}
                  >
                    +Add more answer
                  </button>
                </div>
              </div>
            )}

            <div className="questions-form-footer">
              <div className="questions-footer">
                <button type="submit" className="update-button">
                  Update
                </button>
                <button
                  type="button"
                  className="mx-2 btn btn-main save-button"
                  // onClick={previewJob}
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
