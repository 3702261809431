import React, { useState } from "react";
import "./styles.css";
import DeleteIcon from "../../../assets/icons/delete.svg";

const RecruiterTestCases = () => {
  const [testCases, setTestCases] = useState([
    { id: 1, input: "", output: "" }
  ]);

  const addNewTestCase = () => {
    const newId = testCases.length + 1;
    setTestCases([...testCases, { id: newId, input: "", output: "" }]);
  };

  const handleInputChange = (id, value) => {
    setTestCases(testCases.map(testCase => 
      testCase.id === id ? { ...testCase, input: value } : testCase
    ));
  };

  const handleOutputChange = (id, value) => {
    setTestCases(testCases.map(testCase => 
      testCase.id === id ? { ...testCase, output: value } : testCase
    ));
  };

  const handleDelete = (id) => {
    if (testCases.length > 1) {
      setTestCases(testCases.filter(testCase => testCase.id !== id));
    }
  };

  return (
    <>
      <p className="test-case-header">Enter testcases</p>
      {testCases.map((testCase) => (
        <div key={testCase.id} style={{ display: "flex" }}>
          <div className="test-case-card">
            <div className="input-section">
              <span className="label">Input</span>
              <textarea
                className="test-case-textarea"
                value={testCase.input}
                onChange={(e) => handleInputChange(testCase.id, e.target.value)}
              />
            </div>

            <div className="output-section">
              <span className="label">Output</span>
              <textarea
                className="test-case-textarea"
                value={testCase.output}
                onChange={(e) => handleOutputChange(testCase.id, e.target.value)}
              />
            </div>
          </div>
          <div className="controls" style={{ marginRight: -55 }}>
            <img 
              src={DeleteIcon} 
              height={16} 
              width={16} 
              alt="delete" 
              onClick={() => handleDelete(testCase.id)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      ))}
      
      <button
        type="button"
        className="add-option-btn"
        onClick={addNewTestCase}
      >
        +Add more testcases
      </button>
    </>
  );
};

export default RecruiterTestCases;
