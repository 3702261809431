import { createSlice } from "@reduxjs/toolkit";
import { fetchQuestions } from "../../thunks/questionThunks";

const questionSlice = createSlice({
  name: "quetsionList",
  initialState: {
    allQuestions: {},
    allQnIDs: [],
    attemptedQuestions: {
      "0ab8ffde-2f81-47dd-b895-f99d169cc1e7": [
        "d99e7d63-2b41-4a97-aad7-9bc3e0994810",
      ], // Just tempeorarily hardcoding to show how complted question looks like, this can be removed once submit question issue resolved.
    },
    status: "idle", // idle | loading | succeeded | failed
    error: null,
  },
  reducers: {
    updateQuestions: (state, action) => {
      state.allQuestions = { ...state.allQuestions, ...action.payload };
    },
    submittedQuestions: (state, action) => {
      state.attemptedQuestions = {
        ...state.attemptedQuestions,
        ...action.payload,
      };
    },
    updateQuestionIds: (state, action) => {
      state.allQnIDs.push(...action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        const allQn = action.payload;
        const codingQn = allQn?.questions?.map((elem) => elem.question);
        const mcqQn = allQn.mcqList;
        state.allQuestions = {
          ...allQn,
          finalQuestions: [
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
            ...codingQn,
            ...mcqQn,
          ],
        };
        state.status = "succeeded";
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { updateQuestions, submittedQuestions, updateQuestionIds } =
  questionSlice.actions;
export default questionSlice.reducer;
