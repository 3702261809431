import { useEffect, useState } from "react";
import darkLogo from "./jobify-dark-logo.svg";
import lightLogo from "./jobify-light-logo.svg";
import styles from "./AppNav.module.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Toggle from "./ThemeToggleButton";
import { FaCog, FaPowerOff, FaUser } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import "./AppNav.css";
import { logoutUser } from "../redux/slices/auth/authSlice";
import Logo from "../../src/assets/images/assessmentPadLogo.svg";
import UserDetails from "../assets/images/user-details.svg";
import Logout from "../assets/images/logout.svg";

export default function AppNav({ showRecruiterTabs }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  console.log("LOCATION >>>>", location);

  useEffect(() => {
    if (
      location.pathname === "/recruiters-view" &&
      searchParams.get("tab") === null
    ) {
      navigate("/recruiters-view?tab=1");
    }
  }, [location]);

  const [showPopup, setShowPopup] = useState(false);

  const { user, theme } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handlesignout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  const handleTabChange = (tabID) => () => {
    searchParams.set("tab", tabID);
    navigate(`${location.pathname}?tab=${tabID}`);
  };

  console.log("SEARCH PARAMS >>>>", searchParams.get("tab"));

  const role = localStorage.getItem("role");
  return (
    <>
      {showPopup && user && (
        <>
          <div
            className={styles.profilePopupBg}
            onClick={() => {
              setShowPopup(false);
            }}
          ></div>
          {role === "candidate" ? (
            <div className={[styles.profilePopup].join(" ")}>
              <h5 className="account-heading">My account</h5>
              <div className="d-flex align-items-center mt-3">
                <div className="avatar mr-2">
                  <FaUser />
                </div>
                <div>
                  <span className="user-name">
                    {user.email.split("@")[0]}
                    <div className="role-chip ml-2">
                      {role === "candidate"
                        ? "Candidate profile"
                        : "Recruiter profile"}
                    </div>
                  </span>
                  <br />
                  <span className="user-email">{user.email}</span>
                </div>
              </div>
              <hr style={{ borderColor: "#E2E8F0" }} />
              <div className="signout" onClick={handlesignout}>
                <FaPowerOff className="mr-2" /> Logout
              </div>
            </div>
          ) : (
            <div className="profile-dropdown">
              <div className="dropdown-content">
                <h2 className="dropdown-title">My account</h2>
                <div className="account-info">
                  <div className="account-avatar">JD</div>
                  <div className="account-details">
                    <div className="account-name">John Doe</div>
                    <div className="account-email">john@gmail.com</div>
                  </div>
                </div>
                <div
                  className="recruiter-company-info"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    <div className="account-avatar">J</div>
                    <div className="account-details" style={{ marginLeft: 12 }}>
                      <div className="account-name">Your company</div>
                      <div className="account-email">com@gmail.com</div>
                    </div>
                  </div>
                  <span className="edit-button">Edit</span>
                </div>
                {/* <div className="theme-info"> */}
                {/* <div className="theme-details"> */}
                {/* <div className="theme-text"> */}
                {/* <div className="theme-label">Theme</div> */}
                {/* <div className="theme-value">System</div> */}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
                <div className="break-line"></div>

                <div className="other-settings">Other settings</div>
                <div className="user-details-button">
                  <img
                    width={24}
                    height={24}
                    src={UserDetails}
                    alt="user-details"
                  />
                  <span
                    className="tw-ml-3"
                    style={{ fontSize: 14, fontWeight: 600, color: "#868C8A" }}
                  >
                    User details
                  </span>
                </div>
                {/* </Link> */}
                <button onClick={handlesignout} className=" logout-button">
                  <img width={24} height={24} src={Logout} alt="user-details" />
                  <span className="logout-text"> Logout</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      <div className={[styles.appNav].join(" ")}>
        <div className="d-flex justify-content-between align-items-center">
          <img
            src={theme === "dark" ? Logo : lightLogo}
            alt="logo"
            className="z-2"
            style={{ height: "2.2rem" }}
          />
          {showRecruiterTabs && (
            <div className="recruiters-tabs">
              <div className="d-flex gap-2">
                <div
                  className={`tab ${
                    searchParams.get("tab") == 1 ? "active" : ""
                  }`}
                  onClick={handleTabChange(1)}
                >
                  Post Job
                </div>
                <div
                  className={`tab ${
                    searchParams.get("tab") == 2 ? "active" : ""
                  }`}
                  onClick={handleTabChange(2)}
                >
                  Active Jobs
                </div>
                <div
                  className={`tab ${
                    searchParams.get("tab") == 3 ? "active" : ""
                  }`}
                  onClick={handleTabChange(3)}
                >
                  Question Bank
                </div>
                <div
                  className={`tab ${
                    searchParams.get("tab") == 4 ? "active" : ""
                  }`}
                  onClick={handleTabChange(4)}
                >
                  Create Job
                </div>
                <div
                  className={`tab ${
                    searchParams.get("tab") == 5 ? "active" : ""
                  }`}
                  onClick={handleTabChange(5)}
                >
                  Candidates
                </div>
              </div>
            </div>
          )}
          <div className="d-flex align-items-center z-2">
            <Toggle />
            <div className="d-flex align-items-center">
              <div className="avatar mr-2">
                <FaUser />
              </div>
              <span className="user-email mr-2">{user?.email}</span>
            </div>
            |
            <button
              className="btn"
              type="button"
              onClick={() => {
                setShowPopup((prev) => !prev);
              }}
            >
              <FaCog className="settings-icon" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
