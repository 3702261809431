// CodeEditor.jsx
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { encode as base64_encode } from "base-64";
import { confirmAlert } from "react-confirm-alert";
import axiosInstance from "../axiosInstance";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Split from "@uiw/react-split";

import {
  updateQuestions,
  submittedQuestions,
} from "../redux/slices/questions/questionSlice";
import {
  reviewSubmit,
  runCodeAndSubmitRequest,
  runCodeRequest,
  submitSolution,
} from "./../utilities/requests/runCodeUtility";
import { DEFAULT_SNIPPET } from "../Questions/questions";
import { DEFAULT_QUESTION } from "../basic";
import { useAppSelector } from "../redux/hooks";
import CodeEditor from "./CodeEditor";
import EditorPagination from "./CodeEditor/EditorPagination";
import MCQPage from "./CodeEditor/MCQPage";
import Loader from "./common/Loader";
import TestCase from "./CodeEditor/TestCase";
// Add other modes as required
import "react-toastify/dist/ReactToastify.css";
import "./Editor.scss";
import AppNav from "./AppNav";
import PlayIcon from "../assets/images/play.svg";
import DropdownFilter from "./common/DropdownFilter";

const Editor = () => {
  const { theme, user } = useAppSelector((state) => state.auth);

  const [time, setTime] = useState(() => {
    return parseInt(localStorage.getItem("time") || 905); // changed 1 hr to 15 min
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const minutes = Math.floor(time / 60);
  // const seconds = time % 60;

  const [languageId, setLanguageId] = useState("java");
  const [sourceCode, setSourceCode] = useState(
    DEFAULT_SNIPPET["java"].codeSnippet
  );

  const [codeResult, setCodeResult] = useState(null);
  const [runsubmit, setRunSubmit] = useState(false);

  const [output, setOutput] = useState("");
  const [testMessageClassName, setTestMessageClassName] = useState("hidden");
  const [testCasesResultMessage, setTestCasesResultMessage] = useState("");
  const [isTestPassed, setIsTestPassed] = useState(false);
  const [isCodingRunning, setIsCodeRunning] = useState(false);

  const [isTestCasesRunning, setIsTestCasesRunning] = useState(false);
  const [testCasesResult, setTestCasesResult] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const handleCodeSubmission = useCallback(async () => {
    if (codeResult === null && hasAttemptedSubmit) {
      toast.error("Please Run code before Submit");
    } else {
      const codesubmitted = await codesubmit();
      if (codesubmitted) {
        localStorage.setItem("stopVideoSignal", Date.now().toString());
        setIsModalOpen(true);
      }
    }
  }, [codeResult, sourceCode, setIsModalOpen, codesubmit]);

  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    window.onbeforeunload = (e) => {
      e.preventDefault();
      return "";
    };

    // Unmount the window.onbeforeunload event
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const selector = useSelector((state) => state.questions);

  const { queId } = location.state;
  const [activeQue, setActiveQue] = useState(queId);

  const notify = (message) => {
    toast.info(message);
  };

  useEffect(() => {
    const interval =
      time > 0 &&
      setInterval(() => {
        localStorage.setItem("time", time - 1);
        setTime(time - 1);
      }, 1000);
    if (time === 0) {
      handleCodeSubmission();
    }
    if (time === 50) {
      notify("5 min left");
    }
    if (time === 180) {
      notify("3 minutes left");
    }

    return () => clearInterval(interval);
  }, [time, handleCodeSubmission]);

  useEffect(() => {
    setOutput(isCodingRunning ? "Loading..." : codeResult?.output || "");
  }, [isCodingRunning, codeResult]);

  // useEffect(() => {
  //   if (runsubmit) {
  //     handleCodeSubmission();
  //   }
  // }, [runsubmit]);
  useEffect(() => {
    if (runsubmit) {
      handleCodeSubmission().finally(() => setRunSubmit(false));
    }
  }, [runsubmit]);

  useEffect(() => {
    setIsTestPassed(false);
    if (testCasesResult?.length === 0) {
      return;
    }

    if (testCasesResult.allTestCasesPassed) {
      setIsTestPassed(true);
      setTestMessageClassName("all-testcases-passed");
      setTestCasesResultMessage(
        "Congratulations.. All initial test cases are passed."
      );
    } else if (testCasesResult.allTestCasesFailed) {
      setTestMessageClassName("all-testcases-failed");
      setTestCasesResultMessage("All initial test cases are failed.");
    } else {
      setTestMessageClassName("some-testcases-failed");
      setTestCasesResultMessage(
        `${testCasesResult.passedTestCount} out of ${testCasesResult.totalTestCount} initial test cases passed`
      );
    }
  }, [testCasesResult]);

  const checkValidJob = () => {
    const authData = localStorage.getItem("auth");
    const token = authData;
    const jobId = searchParams.get("jobId");

    axiosInstance
      .get(`v1/jobs/eligibility?jobPostUUID=${jobId}&token=${token}`, {
        headers: {
          accept: "*/*",
        },
      })
      .then((response) => {
        // Handle the response data here
        localStorage.setItem("jobId", jobId);
        // getJobInfo();
      })
      .catch((error) => {
        toast.error("Please contact us at hello@jobify.club");
        // navigate('/candidates-view');
      });
  };

  const getJobInfo = async () => {
    const authData = localStorage.getItem("auth");
    const token = authData;
    const jobId = searchParams.get("jobId");
    try {
      const response = await axiosInstance.get(
        `/v1/jobs/infos?jobId=${jobId}&token=${token}`
      );
      if (!response.data.questionText) {
        response.data.questionText = DEFAULT_QUESTION;
      }
      setJobData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function codesubmit() {
    const submitSolutionRecord = {
      candidateEmail: JSON.parse(localStorage.getItem("email")),
      codeInput: base64_encode(sourceCode),
      codeOutput: base64_encode(codeResult?.output),
      jobPostUUID: localStorage.getItem("jobId"),
      languageId: Number(languageId),
      isTestPassed: isTestPassed,
    };
    try {
      return await submitSolution(submitSolutionRecord).then(
        (response) => response.data
      );
    } catch (error) {
      throw error;
    }
  }

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguageId(selectedLanguage);
    const activeTemplate = selector.allQuestions?.questions
      ?.filter((que) => que["question"]["id"] == activeQue)[0]
      ["codeTemplateList"].filter(
        (code) =>
          code["codeLanguageId"] == "4ba0150b-bc71-4d2f-9a59-5418e57d89eb"
      )[0]["template"];
    // setSourceCode(DEFAULT_SNIPPET[selectedLanguage]?.codeSnippet || '');
    setSourceCode(atob(activeTemplate));
  };
  // Sample url: https://api.jobify.club/v1/video/send-complete?message=dhanushvimal001@gmail.com_jobId=2954271d-be7b-400d-92c7-c4399275893c
  const handleCodeSubmit = () => {
    try {
      const response = axiosInstance.post(
        `/v1/video/send-complete?message=${user.email}_jobid=${searchParams.get(
          "jobId"
        )}`
      );
    } catch (error) {}
  };

  const onQueChange = (val) => {
    setActiveQue(val);
  };

  const allLanguages = [
    { id: "javascript", name: "JavaScript" },
    { id: "typescript", name: "TypeScript" },
    { id: "html", name: "HTML" },
    { id: "scss", name: "CSS / SCSS" },
    { id: "cpp", name: "C++" },
    { id: "java", name: "Java" },
    { id: "python", name: "Python" },
    { id: "php", name: "PHP" },
    { id: "scala", name: "Scala" },
    { id: "rust", name: "Rust" },
    { id: "ruby", name: "Ruby" },
    { id: "go", name: "Golang" },
  ];

  const filterQuestionUpdate = (selectedLanguage) => {
    console.log({ selectedLanguage });
    setLanguageId(selectedLanguage);
    const activeTemplate = selector.allQuestions?.questions
      ?.filter((que) => que["question"]["id"] == activeQue)[0]
      ["codeTemplateList"].filter(
        (code) =>
          code["codeLanguageId"] == "4ba0150b-bc71-4d2f-9a59-5418e57d89eb"
      )[0]["template"];
    setSourceCode(atob(activeTemplate));
  };

  return (
    <div className="editor-container-main">
      <div>
        <AppNav />
      </div>
      <div>
        {!selector.allQuestions.finalQuestions ? (
          <Loader />
        ) : (
          <>
            <EditorPagination activeQue={activeQue} onQueChange={onQueChange} />

            {/* <div className="editor-header">
              <div className="header-actions">
                <div className="btn-timer">
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </div>
              </div>
            </div> */}
            {selector.allQuestions?.finalQuestions?.filter(
              (que) => que["id"] === activeQue
            )[0]?.type ? (
              <MCQPage
                testId={searchParams.get("testId")}
                data={
                  selector.allQuestions?.finalQuestions?.filter(
                    (que) => que["id"] == activeQue
                  )[0]
                }
              />
            ) : (
              <>
                <div className="editor-body">
                  <Split
                    mode="horizontal"
                    style={{
                      height: "100%",
                      boxShadow: "none",
                      alignSelf: "center",
                    }}
                  >
                    <div className="left-section">
                      <div className="question-main">
                        <div className="tab-header">Question 3</div>
                        <div className="tw-p-5">
                          {
                            selector.allQuestions?.questions?.filter(
                              (que) => que["question"]["id"] === activeQue
                            )[0]?.question.description
                          }
                        </div>
                      </div>
                    </div>
                    <div className="right-section">
                      <Split
                        mode="vertical"
                        style={{ height: "100%", boxShadow: "none" }}
                      >
                        <div className="code-editor">
                          <div className="tab-header" style={{ height: 60 }}>
                            Code
                          </div>
                          <div className="all-languages">
                            <DropdownFilter
                              name="languages"
                              styleClass="viewAllQnPageDropdown"
                              dropdownName={
                                languageId.charAt(0).toUpperCase() +
                                languageId.slice(1)
                              }
                              dropdownOptions={allLanguages.map(
                                (lang) => lang.name
                              )}
                              dropdownHandleClick={filterQuestionUpdate}
                            />
                          </div>

                          <div style={{ height: "calc(100% - 100px)" }}>
                            <CodeEditor
                              language={languageId.toLowerCase()}
                              sourceCode={sourceCode}
                              setSourceCode={setSourceCode}
                            />
                          </div>
                        </div>
                        <div
                          className="test-case-container"
                          id="accordionPapanelsStayOpennelsStayOpenExample"
                        >
                          <TestCase />
                        </div>
                      </Split>
                    </div>
                  </Split>
                </div>
                <div className="action-buttons-container">
                  <button
                    className="run-button"
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      checkValidJob();
                    }}
                  >
                    <img style={{ marginRight: 10 }} src={PlayIcon} />
                    Run Code
                  </button>
                  <button
                    className="submit-button"
                    onClick={() => {
                      setHasAttemptedSubmit(true);
                      handleCodeSubmission();
                      setIsModalOpen(true);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Editor;
