import { useMemo, useState } from "react";
import "./recruiterCandidate.scss";
import NoList from "../../../assets/images/no-list.svg";
import "./recruiterCandidate.scss";
import rightPaginationArrow from "../../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../../assets/images/leftPaginationArrow.svg";
import DropdownFilter from "../../common/DropdownFilter";

const jobValues = [
  "Software Engineer",
  "Frontend Developer",
  "Backend Developer",
];

const testValues = ["Test 1", "Test 2", "Test 3"];
// Sample data
const candidates = [
  {
    id: 1,
    name: "Aditya singh",
    email: "bmursingh@gmail.com",
    phone: "+91 9844 444 443",
    tags: ["5+", "Full time", "Remote"],
    avatarColor: "orange",
  },
  {
    id: 2,
    name: "Aditya singh",
    email: "bmursingh@gmail.com",
    phone: "+91 9844 444 443",
    tags: ["5+", "Full time", "Remote"],
    avatarColor: "green",
  },
  {
    id: 3,
    name: "Aditya singh",
    email: "bmursingh@gmail.com",
    phone: "+91 9844 444 443",
    tags: ["5+", "Full time", "Remote"],
    avatarColor: "purple",
  },
  {
    id: 4,
    name: "Aditya singh",
    email: "bmursingh@gmail.com",
    phone: "+91 9844 444 443",
    tags: ["5+", "Full time", "Remote"],
    avatarColor: "blue",
  },
  {
    id: 5,
    name: "Aditya singh",
    email: "bmursingh@gmail.com",
    phone: "+91 9844 444 443",
    tags: ["5+", "Full time", "Remote"],
    avatarColor: "orange",
  },
];

const candidatesPerPage = 5;

export default function RecruiterCandidate() {
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });
  const totalPages = useMemo(
    () => Math.ceil(candidates?.length / candidatesPerPage),
    [candidates]
  );
  const showList = selectedJob && selectedTest;

  const handleSelectJob = (value) => {
    setSelectedJob(value);
  };

  const handleSelectTest = (value) => {
    setSelectedTest(value);
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (buttonRange.start >= currentPage) {
      const prevStart = Math.min(buttonRange.start - 1, totalPages - 10);
      const prevEnd = Math.min(buttonRange.end - 1, totalPages);
      if (currentPage <= buttonRange.start) {
        setButtonRange({ start: prevStart, end: prevEnd });
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = buttonRange.start; i <= buttonRange.end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? "active" : ""}
          // disabled={i > Math.ceil(filteredQuestions.length / 10)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };
  console.log({ showList });

  const handleNextRange = () => {
    if (totalPages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
    if (buttonRange.end <= currentPage) {
      const nextStart = Math.min(buttonRange.start + 1, totalPages - 10 + 1);
      const nextEnd = Math.min(buttonRange.end + 1, totalPages);
      if (currentPage < totalPages) {
        setButtonRange({ start: nextStart, end: nextEnd });
      }
    }
  };
  return (
    <div className="main-container">
      <div className="flex justify-between mb-4 filter-container">
        <div className="filter-section">
          <div className="w-[48%]">
            <div className="relative">
              <DropdownFilter
                name="questionType"
                styleClass="viewAllQnPageDropdown"
                dropdownName={selectedJob || "Select Job"}
                dropdownOptions={jobValues}
                dropdownHandleClick={handleSelectJob}
              />
            </div>
          </div>
          <div className="w-[48%] tw-ml-10">
            <div className="relative">
              <DropdownFilter
                name="questionType"
                styleClass="viewAllQnPageDropdown"
                dropdownName={selectedTest || "Select Test"}
                dropdownOptions={testValues}
                dropdownHandleClick={handleSelectTest}
              />
            </div>
          </div>
        </div>
      </div>

      {!showList ? (
        <div className="no-list-container">
          <div className="text-center">
            <img src={NoList} />
            <h3 className="no-list-text">No list to show</h3>
            <p className="text-description">
              Select from the dropdown above to
              <br />
              view candidate list
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="candidate-list">
            {candidates.map((candidate) => (
              <div key={candidate.id} className="candidate-card">
                <div className="candidate-info">
                  <div
                    className={`avatar ${candidate.avatarColor}`}
                    style={{ height: 60, width: 60 }}
                  >
                    AS
                  </div>
                  <div className="details">
                    <h3>{candidate.name}</h3>
                    <div className="contact-info">
                      <a href={candidate.email}>{candidate.email}</a>
                      <span>{candidate.phone}</span>
                    </div>
                    <div className="tags">
                      {candidate.tags.map((tag) => (
                        <span key={tag} className="tag">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    // onClick={() => navToQuestion(que.id)}
                    className="view-video"
                    style={{
                      backgroundColor: "#B4FF45",
                      color: "#131514",
                      fontWeight: 600,
                    }}
                  >
                    View Video
                  </button>
                  <button className="view-resume">View Resume</button>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            <div className="paginationNoBtns">{renderPaginationButtons()}</div>
            <div className="paginationNextPrevBtn">
              <button
                className="leftPaginationArrow"
                onClick={handlePrevRange}
                disabled={currentPage === 1}
              >
                <img
                  className="rightPaginationArrow"
                  src={leftPaginationArrow}
                  alt="Next Question"
                />
              </button>
              <button
                className="rightPaginationArrow"
                onClick={handleNextRange}
                disabled={currentPage === totalPages}
              >
                <img
                  className="rightPaginationArrow"
                  src={rightPaginationArrow}
                  alt="Next Question"
                />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
