export function registerLanguageProvider(monaco, language, customLibrary) {
  const disposable = monaco.languages.registerCompletionItemProvider(language, {
    triggerCharacters: ['.', ' '],  // Trigger on dot and space
    provideCompletionItems: async (model, position) => {
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };

      // Get built-in suggestions from Monaco
      const defaultProvider = await monaco.languages.typescript.getJavaScriptWorker();
      const worker = await defaultProvider();
      const uri = model.uri.toString();
      const offset = model.getOffsetAt(position);
      
      // Get built-in completions
      let builtInSuggestions = [];
      try {
        const suggestions = await worker.getCompletionsAtPosition(uri, offset);
        if (suggestions) {
          builtInSuggestions = suggestions.entries.map(entry => ({
            label: entry.name,
            kind: translateCompletionItemKind(entry.kind, monaco.languages),
            insertText: entry.name,
            detail: entry.kind,
            range,
          }));
        }
      } catch (error) {
        console.warn('Error getting built-in suggestions:', error);
      }

      // Combine with our custom suggestions
      const suggestions = [
        ...builtInSuggestions,
        ...getBuiltInSuggestions(monaco.languages, language, range),
        ...getCustomSuggestions(monaco.languages, customLibrary, range),
      ];

      return { suggestions };
    },
  });

  monaco.languages.registerHoverProvider(language, {
    provideHover: (model, position) => {
      const word = model.getWordAtPosition(position);
      if (!word) return null;

      const documentation = getDocumentation(monaco.languages, language, word.word) || 
                            getCustomDocumentation(monaco.languages, customLibrary, word.word);

      if (documentation) {
        return {
          contents: [{ value: documentation }],
        };
      }

      return null;
    },
  });

  return disposable;
}

// Helper function to translate TypeScript completion kinds to Monaco completion kinds
function translateCompletionItemKind(kind, languages) {
  const kindMap = {
    'variable': languages.CompletionItemKind.Variable,
    'function': languages.CompletionItemKind.Function,
    'class': languages.CompletionItemKind.Class,
    'interface': languages.CompletionItemKind.Interface,
    'module': languages.CompletionItemKind.Module,
    'property': languages.CompletionItemKind.Property,
    'method': languages.CompletionItemKind.Method,
    'enum': languages.CompletionItemKind.Enum,
    'keyword': languages.CompletionItemKind.Keyword,
    'field': languages.CompletionItemKind.Field,
    'constructor': languages.CompletionItemKind.Constructor,
    'reference': languages.CompletionItemKind.Reference,
    'constant': languages.CompletionItemKind.Constant,
  };

  return kindMap[kind] || languages.CompletionItemKind.Text;
}

function getBuiltInSuggestions(languages, language, range) {
  const commonSuggestions = [
    {
      label: 'console.log',
      kind: languages.CompletionItemKind.Function,
      insertText: 'console.log(${1:object})',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Log to the console',
      range,
    },
  ];

  const languageSpecificSuggestions = {
    java: [
      // Data Types
      { label: 'int', kind: languages.CompletionItemKind.Keyword, insertText: 'int', documentation: 'Integer data type', range },
      { label: 'double', kind: languages.CompletionItemKind.Keyword, insertText: 'double', documentation: 'Double-precision floating-point', range },
      { label: 'float', kind: languages.CompletionItemKind.Keyword, insertText: 'float', documentation: 'Single-precision floating-point', range },
      { label: 'boolean', kind: languages.CompletionItemKind.Keyword, insertText: 'boolean', documentation: 'Boolean data type', range },
      { label: 'char', kind: languages.CompletionItemKind.Keyword, insertText: 'char', documentation: 'Character data type', range },
      { label: 'String', kind: languages.CompletionItemKind.Class, insertText: 'String', documentation: 'String class', range },

      // Control Structures
      {
        label: 'if',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'if (${1:condition}) {\n\t${2}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'If statement',
        range,
      },
      {
        label: 'for',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'for (${1:int i = 0}; ${2:i < n}; ${3:i++}) {\n\t${4}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'For loop',
        range,
      },
      {
        label: 'while',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'while (${1:condition}) {\n\t${2}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'While loop',
        range,
      },
      {
        label: 'switch',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'switch (${1:expression}) {\n\tcase ${2:value}:\n\t\t${3}\n\t\tbreak;\n\tdefault:\n\t\t${4}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Switch statement',
        range,
      },

      // Exception Handling
      {
        label: 'try-catch',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'try {\n\t${1}\n} catch (${2:Exception} e) {\n\t${3}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Try-catch block',
        range,
      },
      {
        label: 'try-catch-finally',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'try {\n\t${1}\n} catch (${2:Exception} e) {\n\t${3}\n} finally {\n\t${4}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Try-catch-finally block',
        range,
      },
      { label: 'throw', kind: languages.CompletionItemKind.Keyword, insertText: 'throw', documentation: 'Throw an exception', range },

      // Class-related
      {
        label: 'class',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'class ${1:ClassName} {\n\t${2}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Class definition',
        range,
      },
      {
        label: 'interface',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'interface ${1:InterfaceName} {\n\t${2}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Interface definition',
        range,
      },
      { label: 'extends', kind: languages.CompletionItemKind.Keyword, insertText: 'extends', documentation: 'Extends keyword for inheritance', range },
      { label: 'implements', kind: languages.CompletionItemKind.Keyword, insertText: 'implements', documentation: 'Implements keyword for interfaces', range },

      // Modifiers
      { label: 'public', kind: languages.CompletionItemKind.Keyword, insertText: 'public', documentation: 'Public access modifier', range },
      { label: 'private', kind: languages.CompletionItemKind.Keyword, insertText: 'private', documentation: 'Private access modifier', range },
      { label: 'protected', kind: languages.CompletionItemKind.Keyword, insertText: 'protected', documentation: 'Protected access modifier', range },
      { label: 'static', kind: languages.CompletionItemKind.Keyword, insertText: 'static', documentation: 'Static modifier', range },
      { label: 'final', kind: languages.CompletionItemKind.Keyword, insertText: 'final', documentation: 'Final modifier', range },

      // Methods
      {
        label: 'main',
        kind: languages.CompletionItemKind.Snippet,
        insertText: 'public static void main(String[] args) {\n\t${1}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Main method',
        range,
      },
      {
        label: 'method',
        kind: languages.CompletionItemKind.Snippet,
        insertText: '${1:public} ${2:void} ${3:methodName}(${4:parameters}) {\n\t${5}\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Method definition',
        range,
      },

      // Common Java classes and methods
      { label: 'System.out.println', kind: languages.CompletionItemKind.Method, insertText: 'System.out.println(${1});', insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet, documentation: 'Print to console', range },
      { label: 'ArrayList', kind: languages.CompletionItemKind.Class, insertText: 'ArrayList<${1:Object}>', insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet, documentation: 'ArrayList class', range },
      { label: 'HashMap', kind: languages.CompletionItemKind.Class, insertText: 'HashMap<${1:KeyType}, ${2:ValueType}>', insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet, documentation: 'HashMap class', range },

      { 
        label: 'List',
        kind: languages.CompletionItemKind.Interface,
        insertText: 'List<${1:Type}>',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'List interface',
        range 
      },
      { 
        label: 'Set',
        kind: languages.CompletionItemKind.Interface,
        insertText: 'Set<${1:Type}>',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Set interface',
        range 
      },
      { 
        label: 'Map',
        kind: languages.CompletionItemKind.Interface,
        insertText: 'Map<${1:KeyType}, ${2:ValueType}>',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Map interface',
        range 
      },
      { 
        label: 'LinkedList',
        kind: languages.CompletionItemKind.Class,
        insertText: 'LinkedList<${1:Type}>',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'LinkedList implementation',
        range 
      },
      { 
        label: 'HashSet',
        kind: languages.CompletionItemKind.Class,
        insertText: 'HashSet<${1:Type}>',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'HashSet implementation',
        range 
      },
      { 
        label: 'TreeMap',
        kind: languages.CompletionItemKind.Class,
        insertText: 'TreeMap<${1:KeyType}, ${2:ValueType}>',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'TreeMap implementation',
        range 
      },

      {
        label: 'equals',
        kind: languages.CompletionItemKind.Method,
        insertText: '@Override\npublic boolean equals(Object obj) {\n\t${1}\n\treturn ${2:false};\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Override equals method',
        range
      },
      {
        label: 'hashCode',
        kind: languages.CompletionItemKind.Method,
        insertText: '@Override\npublic int hashCode() {\n\treturn Objects.hash(${1});\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Override hashCode method',
        range
      },
      {
        label: 'toString',
        kind: languages.CompletionItemKind.Method,
        insertText: '@Override\npublic String toString() {\n\treturn ${1:super.toString()};\n}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        documentation: 'Override toString method',
        range
      },
    ],
  };

  return [...commonSuggestions, ...(languageSpecificSuggestions[language] || [])];
}

function getCustomSuggestions(languages, customLibrary, range) {
  return Object.entries(customLibrary).map(([key, value]) => ({
    label: key,
    kind: languages.CompletionItemKind.Function,
    insertText: key,
    documentation: value.description,
    range,
  }));
}

function getDocumentation(languages, language, word) {
  const docs = {
    java: {
      'int': 'Integer data type',
      'double': 'Double-precision floating-point',
      'float': 'Single-precision floating-point',
      'boolean': 'Boolean data type',
      'char': 'Character data type',
      'String': 'String class',
      'if': 'Conditional statement',
      'for': 'For loop',
      'while': 'While loop',
      'switch': 'Switch statement',
      'try': 'Try block for exception handling',
      'catch': 'Catch block for handling exceptions',
      'finally': 'Finally block for exception handling',
      'throw': 'Throw an exception',
      'class': 'Class definition',
      'interface': 'Interface definition',
      'extends': 'Extends keyword for inheritance',
      'implements': 'Implements keyword for interfaces',
      'public': 'Public access modifier',
      'private': 'Private access modifier',
      'protected': 'Protected access modifier',
      'static': 'Static modifier',
      'final': 'Final modifier',
      'void': 'Void return type',
      'return': 'Return statement',
      'new': 'Create a new instance of a class',
      'this': 'Reference to the current object',
      'super': 'Reference to the parent class',
      'null': 'Null value',
      'true': 'Boolean true value',
      'false': 'Boolean false value',
      'package': 'Package declaration',
      'import': 'Import statement',
      'System.out.println': 'Print to console',
      'ArrayList': 'Dynamic array implementation',
      'HashMap': 'Hash table implementation',
    },
  };

  return docs[language]?.[word] || null;
}

function getCustomDocumentation(languages, customLibrary, word) {
  return customLibrary[word]?.description || null;
}

